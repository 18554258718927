import './Carousel.css';
import { useState } from 'react';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    'https://gsaservicoeconsultoria.com/img/home.png',
    'https://gsaservicoeconsultoria.com/img/home2.jpeg',
    'https://gsaservicoeconsultoria.com/img/home1.jpeg'
  ];

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel-container" onClick={nextImage} style={{ cursor: 'pointer' }}>
      <img src={images[currentIndex]} alt="Carousel" className="carousel-image"/>
      <div className="carousel-button carousel-button-prev" onClick={(e) => { e.stopPropagation(); previousImage(); }}>&#60;</div>
      <div className="carousel-button carousel-button-next" onClick={(e) => { e.stopPropagation(); nextImage(); }}>&#62;</div>
    </div>
  );
};

export default Carousel;