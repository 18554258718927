import "../HomeSection.css";
import PhoneDisplay from './../../../components/PhoneDisplay/PhoneDisplay';

const HomeSection = () => {
  return (
    <section id= "topo">
    <div className="home-section">
      <div className="app">
      <PhoneDisplay />
      </div>
      <div className="text-content">
        <h1>Eleve a eficácia das equipes de endemias em até 35% com nosso aplicativo.</h1>
        <p>Ao substituir os formulários de papel, o software oferece a localização dos agentes em tempo real e simplifica a geração instantânea de relatórios SisPNCD com apenas um clique.</p>
        <a href="https://wa.me/553599262983" target="_blank" rel="noopener noreferrer">
        <button className="cta-button">FALE CONOSCO</button>
        </a>
      </div>
    </div>
    </section>
  );
};

export default HomeSection;
