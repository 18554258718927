import './PainelAdministrativo.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLocation, faContactCard, faDatabase } from '@fortawesome/free-solid-svg-icons';
import CarouselNotebook from './../../components/CarouselNotebook/CarouselNotebook';


const PainelAdministrativo = () => {
  return (
<section id= "painel">
    <div className="alinhamentoCentro">
      <div className="panel">
      <h2>Painel para Gestores: Localização dos agentes em tempo real.</h2>
      </div>
      <div className="notebook-frame">
      <div className="carousel-container">
        <CarouselNotebook />
      </div>
      </div>
      <div className="colunas2">
        <div className="column">
          <div className="alinhamentoCentro">
            <div className="home">
            <div className="icone">
              <FontAwesomeIcon icon={faHome} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <h1>Imóveis Verificados e Pendentes</h1>
            <p>Tenha em tempo real o acompanhamento do número de vistorias realizadas, identifique os imóveis já verificados e quantifique aqueles que ainda aguardam inspeção. Nossa plataforma proporciona uma visão abrangente de todas as atividades voltadas ao combate do Aedes Aegypti.</p>
            <h1></h1>
            <div className="iconeMira">
              <FontAwesomeIcon icon={faLocation} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <h1>Gestão Eficiente com Localização em Tempo Real</h1>
            <p>Nossa solução oferece um monitoramento preciso e em tempo real da localização dos agentes, facilitando a coordenação das equipes em campo. Com isso, a gestão das equipes externas torna-se mais estratégica e eficaz, permitindo um foco direcionado na execução precisa e eficiente das visitas. Superamos os desafios de comunicação e organização, promovendo um ambiente de trabalho integrado e produtivo.</p>
          </div>
          </div>
        </div>
        <div className="column">
          <div className="alinhamentoCentro">
            <div className="iconeCartao">
              <FontAwesomeIcon icon={faContactCard} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <h1>Aumente a Produtividade com Dados Concretos</h1>
            <p>Identifique os destaques entre os agentes, promova reuniões produtivas e estabeleça metas claras, apoiadas em dados precisos. Nossa ferramenta é projetada para embasar decisões estratégicas, incentivando o crescimento individual e coletivo, fortalecendo o compromisso com a excelência no combate às endemias.</p>
            <div className="iconeRel">
              <FontAwesomeIcon icon={faDatabase} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <h1>Relatórios Prontos para o SisPNCD</h1>
            <p>Simplificamos seu trabalho ao gerar relatórios detalhados e prontos para serem inseridos no SisPNCD. Elimine a necessidade de preparações manuais extensas ao final do dia ou da semana, permitindo que os agentes de endemias concentrem-se em realizar mais vistorias e superem as metas anuais com eficiência.</p>
          </div>
        </div>
      </div>
    </div >
    </section>
  );
}

export default PainelAdministrativo;
