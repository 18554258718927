import '../SisPncd/Sis.css';
import pncd from '../../assets/Pncd.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faWifi } from '@fortawesome/free-solid-svg-icons';
import PhoneDisplay from './../../../src/components/PhoneDisplay/PhoneDisplay';

const Sis = () => {
    return (
        <>
            <div className="alinhamento">
                <h2>Elimine todos os formulários do SisPNCD</h2>
                <img className="pncd" src={pncd} alt="Imagem do notebook"/>
            </div>
            <div className="textoCentral">
                <h2>Adquira no formato SAAS</h2>
                <p>Nosso painel administrativo funciona em qualquer navegador e nosso aplicativo em qualquer celular ou tablet Android. Trabalhamos com preços acessíveis, na maioria das vezes abaixo do valor de dispensa de licitação e com planos anuais de assinatura.</p>
                <h2>Segurança com Cloud Computing</h2>
                <p>Os dados ficam salvos em segurança nos melhores servidores.</p>
                <h2>Impressão de formulários</h2>
                <p>Caso o gestor necessite de qualquer formulário impresso basta realizar a solicitação na própria plataforma. Disponibilizamos automaticamente preenchido e assinado digitalmente em formato PDF.</p>

            </div>
            <div className='segundaparte'>
                <h2>Conheça também o GSA: Cidadão</h2>
                <div className="nossoAplicativo">
                <p>Nosso aplicativo, disponível exclusivamente para os residentes das cidades associadas à nossa plataforma, apresenta uma solução inovadora para combater a propagação do mosquito Aedes. Por meio desta ferramenta, os usuários podem reportar de maneira conveniente os locais com focos de infestação, permitindo que anexem fotos para uma identificação mais precisa. Além disso, o aplicativo oferece uma funcionalidade educacional gratuita para todo o Brasil, que orienta os moradores a realizarem inspeções regulares em suas residências a cada 7 dias. O objetivo dessa funcionalidade é não apenas informar, mas também incentivar o envolvimento ativo da comunidade na prevenção e controle do mosquito, fortalecendo assim a luta coletiva contra doenças como dengue, zika e chikungunya.</p>
                </div>
                <div className="colunas3">
                    <div className="column">
                        <div className="alinhamentoCentro">
                        <div className="guia">
                            <div className="iconeLoc">
                                <FontAwesomeIcon icon={faLocationDot} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
                            </div>
                            <titulo>Guia de Vistoria Residencial Contra o Aedes: 13 Etapas Essenciais</titulo>
                            <div className="label">
                            <p>Realizar uma vistoria completa da sua residência a cada 7 dias é crucial para interromper o ciclo de vida larval do mosquito Aedes e prevenir a proliferação desse vetor de doenças graves. Compreendemos que completar a inspeção de uma só vez pode ser desafiador, por isso, nosso aplicativo facilita o processo, permitindo que você salve o progresso das etapas concluídas. Assim, caso precise pausar, basta retomar de onde parou. Siga nosso checklist de 13 etapas para garantir uma vistoria eficaz.</p>
                            </div>
                            <h1></h1>
                            <div className="iconeWifi">
                                <FontAwesomeIcon icon={faWifi} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
                            </div>
                            <titulo>Maximizando a Eficiência com Continuidade Offline</titulo>
                            <p>Nosso aplicativo garante que a ausência de conexão à internet nunca represente um desafio. Continue trabalhando sem interrupções, armazenando os dados localmente e sincronizando tudo ao final do dia por meio de Wi-Fi. Concentre-se plenamente em sua missão, independentemente da disponibilidade de conexão.</p>
                            </div>
                        </div>
                    </div>
                    <div className="app">
                    <PhoneDisplay />
                    </div>
                    {/* <div className="column">
                        <div className="alinhamentoCentro">
                            <div className="iconeRelogio">
                                <FontAwesomeIcon icon={faClock} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
                            </div>
                            <titulo>Aprimorando Eficiência e Produtividade durante as Visitas</titulo>
                            <p>Nosso aplicativo automatiza o registro de início e término das visitas, melhorando a gestão do tempo para agentes e supervisores. Essa funcionalidade não apenas simplifica o controle do cronograma, mas também aumenta a eficiência e produtividade, incentivando uma abordagem mais estratégica e eficaz na área da saúde pública.</p>
                            <div className='utilizacaoDados'>
                            <div className="iconeMsg">
                                <FontAwesomeIcon icon={faMessage} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
                            </div>
                            <titulo>Utilização de Dados em Tempo Real para Aumentar a Eficiência</titulo>
                            <p>Com nosso aplicativo, despeça-se dos formulários em papel! Desenvolvido para simplificar e acelerar as tarefas dos agentes de endemias, depois de experimentar a praticidade e eficiência, você não vai querer voltar atrás.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>

    );
}

export default Sis;
