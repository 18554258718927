import "./PhoneDisplay.css";
import Carousel from './../Carrossel/Carousel';

const PhoneDisplay = () => {
  return (
    <div className="phone-frame">
      <img
        src="https://mockuphone.com/Images/devices_picture/apple-iphone13promax-silver-portrait.png"
        alt="Celular"
        className="phone-image"
      />
      <div className="carousel-container">
        <Carousel />
      </div>
    </div>
  );
};

export default PhoneDisplay;