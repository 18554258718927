import './Content.css';

const Content = () => {
  return (
    <div className="content">
      {/* Conteúdo principal aqui */}
    </div>
  );
};

export default Content;
