import '../Vantagens/Vantagens.css';

const Vantagens = () => {
    return (
        <section id= "vantagens"> 
        <div className="vantagens">
            <h2>Garantia de Verificação de Visitas Confiável</h2>
            <p>Nossa plataforma assegura a autenticidade de cada visita por meio do registro automático do endereço do imóvel, utilizando a geolocalização do agente no momento exato da vistoria. Essa abordagem reforça a precisão e a integridade do processo de vistoria, promovendo transparência e confiança.</p>

            <h2>Compromisso com Visitas de Alta Qualidade</h2>
            <p>Nosso aplicativo registra com precisão o horário de entrada e saída do agente em cada imóvel, garantindo que o tempo dedicado à vistoria seja adequado. Essa funcionalidade promove a realização de visitas completas e eficientes, sem atalhos ou desperdícios de tempo, assegurando a qualidade do trabalho em campo.</p>
            <h2>Maximize a Eficiência com Tecnologia Digital</h2>
            <p>Nossa tecnologia elimina a necessidade de cálculos manuais de produtividade, uma prática obsoleta na era digital. Em vez de gastar horas diárias preenchendo formulários em papel, nossos agentes e supervisores podem dedicar seu tempo diretamente às vistorias. Nosso software prepara os dados para o SisPNCD em questão de segundos, permitindo que todos aproveitem ao máximo seu tempo e aumentem a eficácia de suas operações.</p>
        </div>
        </section>
    );
}

export default Vantagens;
