import '../Aplicaitvo/Aplicativo.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faWifi} from '@fortawesome/free-solid-svg-icons';
import PhoneDisplay from '../../components/PhoneDisplay/PhoneDisplay';

const Aplicativo = () => {
  return (
    <section id= "app">
    <div className="alinhamentoCentro">
      <div className="colunas3">
        <div className="column">
          <div className="alinhamentoCentro">
            <div className="gps">
            <div className="icone">
              <FontAwesomeIcon icon={faLocationDot} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <titulo className="tituloIcones">Melhorando a Precisão das Visitas por meio de Georreferenciamento.</titulo>
            <div className="text">
            <p>Nosso sistema aproveita a tecnologia GPS para registrar automaticamente as coordenadas das visitas, garantindo precisão e simplificando o mapeamento das atividades de campo. Isso fortalece a credibilidade do trabalho realizado, possibilitando que agentes e supervisores compartilhem dados precisos e melhorem a eficiência das iniciativas de saúde pública.</p>
            </div>
            <h1></h1>
            <div className="iconeWifi">
              <FontAwesomeIcon icon={faWifi} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <titulo>Maximizando a Eficiência com Continuidade Offline.</titulo>
            <div className="text">
            <p>Nosso aplicativo garante que a ausência de conexão à internet nunca represente um desafio. Continue trabalhando sem interrupções, armazenando os dados localmente e sincronizando tudo ao final do dia por meio de Wi-Fi. Concentre-se plenamente em sua missão, independentemente da disponibilidade de conexão.</p>
          </div>
          </div>
          </div>
        </div>
        <div className="app">
        <PhoneDisplay />
        </div>
        {/* <div className="column">
          <div className="alinhamentoCentro">
          <div className="aprimorando">
            <div className="iconeRelogio">
              <FontAwesomeIcon icon={faClock} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <titulo>Aprimorando Eficiência e Produtividade durante as Visitas.</titulo>
            <div className="text">
            <p>Nosso aplicativo automatiza o registro de início e término das visitas, melhorando a gestão do tempo para agentes e supervisores. Essa funcionalidade não apenas simplifica o controle do cronograma, mas também aumenta a eficiência e produtividade, incentivando uma abordagem mais estratégica e eficaz na área da saúde pública.</p>
            </div>
            <div className="iconeMsg">
              <FontAwesomeIcon icon={faMessage} style={{ color: "#1d1d8a", width: '90px', height: '90px' }} />
            </div>
            <titulo>Utilização de Dados em Tempo Real para Aumentar a Eficiência.</titulo>
            <div className="text">
            <p>Com nosso aplicativo, despeça-se dos formulários em papel! Desenvolvido para simplificar e acelerar as tarefas dos agentes de endemias, depois de experimentar a praticidade e eficiência, você não vai querer voltar atrás.</p>
            </div>
          </div>
          </div>
        </div> */}
      </div>
      <div className="tituloFinal">
      <titulo>Com nosso aplicativo, dê adeus aos formulários de papel! Projetado para facilitar e agilizar as atividades dos agentes de endemias, uma vez que você experimentar a praticidade e eficiência, não vai querer outra forma de trabalhar.</titulo>
      </div>
    </div>
    </section>
  );
}

export default Aplicativo;
