import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-info">
        <p>
          <span className="icon">🏢</span> CNPJ: 54.757.407/0001-97
        </p>
        <p>
          <span className="icon">📞</span>
          <a
            href="https://wa.me/553599262983"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-link"
          >
            +55 35 9926-2983
          </a>
        </p>
        <p>
          <span className="icon">📧</span>{" "}
          <a
            href="mailto:contato@gsaservicoeconsultoria.com"
            className="email-link"
          >
            contato@gsaservicoeconsultoria.com
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
