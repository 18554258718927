import '../Contato/Contato.css';

const Contato = () => {
    return (
        <section id= "contato"> 
        <div className="alinhamentoEsquerda">
            <h2>Quem Somos</h2>
            <p>Somos uma startup mineira de tecnologia. Fornecemos ferramentas para o combate ao Aedes Aegypti. O aplicativo e o painel administrativo foram criados para aumentar a eficiência das equipes de vigilância epidemiológica. Atacamos problemas específicos , nosso objetivo é garantir a eficiência das vistorias e contribuirmos para o fim do Aedes Aegypti.</p>
        </div>
        </section>
    );
}

export default Contato;
